<template>
  <div>
    <b-card
      id="pdfGenerateDiv"
      ref="pdfGenerateDivRef"
      no-body
      class="invoice-preview-card p-1 main-table"
    >
      <div>
        <div
          class="card__inner"
        >
          <table style="width: 100%; page-break-after: auto; border-collapse:separate; border-spacing: 10px 5px;">
            <thead>
              <tr>
                <td>
                  <!-- Header -->
                  <b-card-body class="invoice-padding pb-0">

                    <div class="d-flex justify-content-between flex-md-row invoice-spacing mt-0">
                      <div>
                        <div class="logo-wrapper">
                          <img
                            style="height:60px;object-fit:contain;"
                            :src="purchaseRequest.company ? (purchaseRequest.company.logo ? purchaseRequest.company.logo : '/nirvana-memorial-garden-logo.png') : '/nirvana-memorial-garden-logo.png'"
                            alt="Logo"
                            crossorigin="anonymous"
                          >
                        </div>
                      </div>
                      <div
                        style="min-width: fit-content; width: fit-content;"
                        class="px-2"
                      >
                        <h4 style="text-align: center; text-transform: uppercase; color: #000000; font-weight: bold; font-size: 12px; line-height: 18px;">
                          Quotation Comparison and Approval Form
                        </h4>
                      </div>
                      <div class="mt-md-0 mt-2 text-right">
                        <h4
                          class="invoice-title"
                          style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                        >
                          QCF-{{ purchaseRequest.stringID }}
                        </h4>
                        <div class="invoice-date-wrapper">
                          <p
                            class="invoice-date"
                            style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                          >
                            <span
                              class="semi-bold"
                              style="font-weight: bold;"
                            >Prepared By:</span> {{ quotation.createdBy ? quotation.createdBy.name : '' }}, {{ quotation.createdBy ? quotation.createdBy.position : '' }}, {{ quotation.createdBy ? (quotation.createdBy.department ? (quotation.createdBy.department.length ? quotation.createdBy.department[0].name : '') : '') : '' }}
                          </p>
                        </div>
                        <div class="invoice-date-wrapper">
                          <p
                            class="invoice-date"
                            style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                          >
                            <span
                              class="semi-bold"
                              style="font-weight: bold;"
                            >Created On:</span> {{ dateFormatWithTime(quotation.createdAt) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </b-card-body>

                  <!-- Spacer -->
                  <hr
                    class="invoice-spacing"
                    style="margin-top:25px!important"
                  >
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <!-- Invoice Client & Payment Details -->
                  <b-card-body
                    class="invoice-padding pt-0 main__body"
                  >
                    <b-row class="invoice-spacing">
                      <b-col
                        cols="12"
                        class="p-0 item__description_table"
                      >
                        <b-table-simple
                          ref="teamsTable"
                          class="position-relative has_padding quote__table borderless__table"
                          responsive
                          caption-top
                          style="page-break-after: auto; page-break-inside: avoid;"
                        >
                          <b-thead head-variant="dark">
                            <b-tr
                              v-if="quotation.comparedVendors"
                              class="group__tag group__tag__white_bg"
                            >
                              <b-th
                                class="empty"
                                colspan="4"
                              >
                                <table class="qcf-table-header">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <span class="text-bold-black">Company</span>
                                      </td>
                                      <td>{{ purchaseRequest.company ? purchaseRequest.company.name : '' }}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span class="text-bold-black">Justification</span>
                                      </td>
                                      <td>{{ purchaseRequest.purpose }}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span class="text-bold-black">Department</span>
                                      </td>
                                      <td>{{ purchaseRequest.department ? purchaseRequest.department.name : '' }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </b-th>
                              <b-th
                                v-for="(vndr, index) in quotation.comparedVendors"
                                :key="index"
                                class="group__tag_info"
                                colspan="3"
                              >
                                <span
                                  class="text-capitalize"
                                  style="font-weight: bold;"
                                >
                                  {{ vndr.status == 'approved' ? 'Approved Vendor' : 'Not In Vendor List' }}
                                </span>
                                <span
                                  class="vn__name"
                                >{{ vndr.name }}</span>
                                <span class="vn__gst">{{ vndr.code }}</span>
                              </b-th>
                            </b-tr>

                            <b-tr class="group__tag">
                              <b-th style="border-left: 1px solid #e7edf5 !important;">
                                No
                              </b-th>
                              <b-th style="font-weight: bold;">
                                Item Description
                              </b-th>
                              <b-th style="font-weight: bold;">
                                Qty
                              </b-th>
                              <b-th style="border-right: 1px solid #e7edf5 !important;">
                                Unit
                              </b-th>
                              <b-th
                                v-for="(vndr, index) in quotation.comparedVendors"
                                :key="index"
                                class="group__tag_info"
                                colspan="3"
                                style="border-right: 1px solid #e7edf5 !important;"
                              >
                                <span
                                  class="mr-50 unit_price"
                                  style="font-weight: bold;"
                                >u. price($)</span>
                                <span
                                  class="mr-50 total_price"
                                  style="font-weight: bold;"
                                >TotaL($)</span>
                              </b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody
                            v-if="quotation.comparedVendors && quotationItems.length"
                            class="white-body-background"
                          >
                            <b-tr
                              v-for="(item, index) in quotationItems"
                              :key="index"
                            >
                              <b-td style="border-right: none !important;">
                                {{ index + 1 }}
                              </b-td>
                              <b-td style="border-right: none !important; max-width: 15rem;">
                                <span
                                  class="text-bold"
                                  style="font-weight:bold"
                                >{{ item.itemsMaster }}</span>
                                <br>
                                <span
                                  class="text-pre"
                                >{{ item.description }}</span>
                              </b-td>
                              <b-td style="border-right: none !important;">
                                {{ item.quantity }}
                              </b-td>
                              <td>
                                {{ item.unit }}
                              </td>
                              <b-td
                                v-for="(vndr, key) in quotation.comparedVendors"
                                :key="key"
                                class="group__tag_info "
                                colspan="3"
                              >
                                <span class="unit_price">{{ item.unitCost[key] }}</span>
                                <span class="unit_price">{{ item.unitCost[key] ? numberFormat((item.quantity * item.unitCost[key])) : 0.00 }}</span>
                                <span v-if="item.gst[key] == 'yes'">
                                  GST
                                </span>
                              </b-td>
                            </b-tr>
                            <b-tr class="cal_row subtotal_row">
                              <td
                                colspan="4"
                                style="background-color: #F5F8FB !important;"
                              />
                              <b-td
                                v-for="(vndr, key) in quotation.comparedVendors"
                                :key="key"
                                class="group__tag_info "
                                colspan="3"
                                style="padding-left: 8px !important; padding-top: 5px !important; background-color: #F5F8FB !important;"
                              >
                                <span
                                  class="mr-50 semi-bold"
                                  style="font-weight: bold;"
                                >Subtotal</span> <span class="mr-50">{{ numberFormat(getSubTotal(key)) }}</span><br>
                                <span
                                  class="mr-50 semi-bold"
                                  style="font-weight: bold;"
                                >GST</span> <span class="mr-50">{{ getGST(key) == '-' ? '-' : (`${getGST(key)} (${quotation.gstList ? (quotation.gstList[key] ? quotation.gstList[key] : quotation.gstPercentage) : quotation.gstPercentage}%)`) }}</span><br>
                                <span
                                  class="mr-50 semi-bold"
                                  style="font-weight: bold;"
                                >Grand Total</span> <span
                                  class="mr-50 semi-bold"
                                  style="font-weight: bold;"
                                >{{ numberFormat(getGrandTotal(key)) }}</span>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </td>
              </tr>
              <tr
                style="
                        width: 100%; page-break-before: auto;
                        page-break-after: auto;
                        page-break-inside: avoid;
                      "
                class="footer-class-a"
              >
                <td>
                  <div>
                    <b-card-body class="invoice-padding pb-0 footer__area">
                      <b-row style="font-size: 11px;">
                        <b-col
                          cols="4"
                          md="4"
                        >
                          <span class="text-bold-black">Recommended External Provider</span>
                          <br>
                          <span>{{ quotation.recommendedProviderName }}</span>
                        </b-col>
                        <b-col
                          cols="8"
                          md="8"
                        >
                          <span class="text-bold-black">Remarks</span>
                          <br>
                          <span class="text-pre">{{ quotation.remarks }}</span>
                        </b-col>
                      </b-row>
                    </b-card-body>
                    <div :style="{ height: gapHeight+'mm' }" />
                    <!-- Spacer -->
                    <hr class="invoice-spacing">

                    <!-- Invoice Description: Total -->
                    <b-card-body class="invoice-padding pb-0 footer__area">
                      <b-row>

                        <!-- Col: Sales Persion -->
                        <b-col
                          v-for="(signatory, index) of quotation.signatories"
                          :key="index"
                          cols="3"
                          md="3"
                          class="mt-md-1 mt-1"
                          order="2"
                          order-md="1"
                        >
                          <b-card-text class="mb-0">
                            <span
                              class="font-weight-bold"
                              style="font-weight: bold;"
                            >{{ signatory.type }}</span>
                            <div class="sign-box">
                              <div v-if="signatory.signed == true">
                                <div v-if="signatory.name">
                                  <span>{{ signatory.name }}</span>
                                </div>
                              </div>
                              <div v-else>
                                <p>Pending Signature</p>
                              </div>
                            </div>
                            <p
                              class="ml-75 semi-bold"
                              style="font-weight: bold;"
                            >
                              {{ signatory.name || '' }}
                            </p>
                            <p class="ml-75">
                              {{ signatory.department || '' }}
                            </p>
                            <p class="ml-75">
                              {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
                            </p>
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BCardText, VBTooltip, BTableSimple, BTbody, BThead,
  BTr, BTh, BTd, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTh,
    BTd,
    BCardBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      elHeight: 0,
      gapHeight: 1,
      purchaseRequest: JSON.parse(localStorage.getItem('purchaseRequest')),
      quotation: JSON.parse(localStorage.getItem('quotation')),
      quotationItems: JSON.parse(localStorage.getItem('quotationItems')),
    }
  },
  //   mounted() {
  //     this.$http.get(`download/quotation/${this.$route.params.id}/show`)
  //       .then(response => {
  //         this.purchaseRequest = response.data.purchaseRequest || {}
  //         this.quotation = response.data.quotation || {}
  //         this.quotationItems = response.data.quotationItems || []
  //       })
  //   },
  methods: {
    getSubTotal(key) {
      const vendor = this.quotation.comparedVendors[key]
      let total = 0
      if (vendor.code === 'GST Inclusive') {
        this.quotationItems.forEach(item => {
          total += item.unitCost[key] ? item.quantity * item.unitCost[key] : 0
        })
        total -= this.getGST(key)
      } else {
        this.quotationItems.forEach(item => {
          total += item.unitCost[key] ? item.quantity * item.unitCost[key] : 0
        })
      }

      const discount = this.quotation.discountList[key] ? this.quotation.discountList[key] : 0
      const dCost = this.quotation.deliveryCost[key] ? this.quotation.deliveryCost[key] : 0

      total = total - discount + parseFloat(dCost)
      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    getGST(key) {
      // eslint-disable-next-line no-nested-ternary
      const gstPercentageVal = this.quotation.gstList ? (this.quotation.gstList[key] ? this.quotation.gstList[key] : this.quotation.gstPercentage) : this.quotation.gstPercentage
      const vendor = this.quotation.comparedVendors[key]
      if (vendor.code === 'No GST') {
        return '-'
      }

      if (vendor.code === 'GST Inclusive') {
        let gstableAmount = 0
        this.quotationItems.forEach(item => {
          gstableAmount += (item.gst[key] && item.gst[key] === 'yes') ? item.quantity * (item.unitCost[key] ? item.unitCost[key] : 0) : 0
        })
        // eslint-disable-next-line no-mixed-operators
        const gstAmount = ((gstableAmount / (100 + parseFloat(gstPercentageVal))) * parseFloat(gstPercentageVal))
        return Number.isNaN(gstAmount) ? '0.00' : gstAmount.toFixed(2)
      }

      let total = 0

      this.quotationItems.forEach(item => {
        total += (item.gst[key] && item.gst[key] === 'yes') ? item.quantity * (item.unitCost[key] ? item.unitCost[key] : 0) : 0
      })

      // eslint-disable-next-line operator-assignment
      total = ((gstPercentageVal / 100) * total)

      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    getGrandTotal(key) {
      return ((this.getGST(key) === '-' ? 0 : parseFloat(this.getGST(key))) + parseFloat(this.getSubTotal(key))).toFixed(2)
    },
  },
}
</script>

  <style lang="scss" scoped>
      .per-page-selector {
          width: 90px;
      }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  <style lang="scss">
      body div#app.semi-dark-layout {
          background: #fff !important;
          background-repeat: no-repeat !important;
          background-position: center bottom !important;
          background-size: 100% auto !important;
      }
      body {
          background: #fff !important;
      }
      .card {
          box-shadow: none !important;
      }
  </style>
  <style>
      @media print {
          html, body {
              height: 99%;
          }
      }
  </style>
